@tailwind base;
@tailwind components;
@tailwind utilities;

.image-hover {
  transition:  all 0.3s ease-in-out;
}

.image-hover:hover {
  transform: scale(1.1);
}
.underline-effect{
  position: relative;
  cursor: pointer;
}

.underline-effect::after{
  content: "";
  position: absolute;
  background-color: #a5a4a4;
  height: 1.7px;
  width:100%;
  left: 0;
  bottom: -5px;
  transition: 0.3s;
}
.underline-effect:hover::after{
  width: 0%;
}

.slick-slider div{
  margin:0rem 0.5rem;
}

.all-strength{
  animation: move-rtl 6000ms linear infinite;
}

@keyframes move-rtl {
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translate(-100%);
  }
}

.react-images__view-wrapper{
  display: flex !important;
  justify-content: center !important;
}
.input {
  @apply appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500;
}

.navbar{
  background-color: transparent;
  color: white;
}
.navbar:hover{
  color: black;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
 
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
/* .navbar-background{
  filter: grayscale(100%);
} */

.active_navbar{
  color: black;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
 
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
/* .logo{
  filter: grayscale();
} */


/*  */


.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* Aspect ratio for a 16:9 video */
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heroBanner {
  width: 100%;
  height: 450px;
  background-color: rgb(10, 10, 10);
  display: flex;
  align-items: center;
  position: relative;
}

/* For medium screens */
@media (min-width: 768px) {
  .heroBanner {
      height: 700px;
  }
}

.heroBanner .backdrop-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  overflow: hidden;
}

.heroBanner .backdrop-img .lazy-load-image-background {
  width: 100%;
  height: 100%;
}

.heroBanner .backdrop-img .lazy-load-image-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.heroBanner .opacity-layout {
  width: 100%;
  height: 250px;
  background: linear-gradient(180deg, rgba(128, 83, 13, 0) 0%, #3d2503 79.17%);
  /* background: linear-gradient(180deg, rgba(255, 152, 0, 0) 0%, #CCCCCC 79.17%); */

  position: absolute;
  bottom: 0;
  left: 0;
}

.heroBanner .heroBannerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
  position: relative;
  top:"40%";
  max-width: 800px;
  margin: 0 auto;
}

.heroBanner .heroBannerContent .hero-title {
  font-size: 50px;
  margin-bottom: 10px;
  font-weight: bold;
}

/* For medium screens */
@media (min-width: 768px) {
  .heroBanner .heroBannerContent .hero-title {
      font-size: 90px;
      margin-bottom: 0;
  }
}

.heroBanner .heroBannerContent .hero-subTitle {
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 500;
}

/* For medium screens */
@media (min-width: 768px) {
  .heroBanner .heroBannerContent .hero-subTitle {
      font-size: 24px;
  }
}

.heroBanner .heroBannerContent .hero-search {
  display: flex;
  align-items: center;
  width: 100%;
}

.heroBanner .heroBannerContent .hero-search input {
  width: calc(100% - 100px);
  height: 50px;
  outline: 0;
  border: 0;
  background-color: white;
  color: var(--black);
  border-radius: 30px 0 0 30px;
  padding: 0 15px;
  font-size: 14px;
}

/* For medium screens */
@media (min-width: 768px) {
  .heroBanner .heroBannerContent .hero-search input {
      width: calc(100% - 150px);
      height: 60px;
      font-size: 20px;
      padding: 0 30px;
  }
}

.heroBanner .heroBannerContent .hero-search button {
  width: 100px;
  height: 50px;
  background: var(--gradient);
  color: white;
  border: none;
  outline: none;
  border-radius: 0 30px 30px 0;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

/* For medium screens */
@media (min-width: 768px) {
  .heroBanner .heroBannerContent .hero-search button {
      height: 60px;
      width: 150px;
      font-size: 22px;
  }
}


.swiper-pagination {
  margin-top: 10px; /* Adjust as needed */
}